import dynamic from 'next/dynamic';
import styled from 'styled-components';

import { useRouter } from 'next/router';
import { APP_IMG } from 'src/constants';
import { covers } from 'src/modules/imagescover';
import { DATALAYER_TYPE, sendDataLayerGlobal } from 'src/utils/datalayer/send-datalayer';

const Carousel = dynamic(import('react-slick'));

type Props = {
  data: covers[]
}

const SectionCover = ({ data }: Props) => {
  const router = useRouter();
  const urlImage = `${APP_IMG}/uploads/images/event/`;

  const settings = {
    dots: false,
    infinite: true,
    speed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000
  };

  return (
    <>
      <SectionCoverDesktop className='show-desktop'>
        {
          <Carousel {...settings} >
            {
              data.filter(data => data.imagePath !== null).map((item, index) => <div key={index} className='banner-special'>
                <a
                  href={!item.url ? '#' : item.url}
                  tabIndex={-1}
                  title={item.title}
                  target={!item.url ? '_self' : '_blank'}
                  rel='noreferrer noopenner'
                  onClick={() =>
                    item.url &&
                    sendDataLayerGlobal({
                      type: DATALAYER_TYPE.TRACK_INDEX,
                      router: router?.pathname,
                      section: 'imagecover',
                      data: {
                        title: item.title,
                        heading: 'imagecover',
                        carousel: index + 1
                      }
                    })
                  }
                >
                  <img src={`${urlImage}${item.imagePath}`} alt={item.title} title={item.title} width='100%' height='100%'
                    loading='lazy' />
                </a>
              </div>)
            }
          </Carousel>
        }
      </SectionCoverDesktop>
      <SectionCoverMobile className='show-mobile'>
        {
          <Carousel {...settings} >
            {
              data.filter(data => data.imagePathMobile !== null).map((item, index) => <div key={index} className='banner-special'>
                <a
                  href={!item.url ? '#' : item.url}
                  tabIndex={-1}
                  title={item.title}
                  target={!item.url ? '_self' : '_blank'}
                  rel='noreferrer noopenner'
                  onClick={() =>
                    item.url &&
                    sendDataLayerGlobal({
                      type: DATALAYER_TYPE.TRACK_INDEX,
                      router: router?.pathname,
                      section: 'imagecover',
                      data: {
                        title: item.title,
                        heading: 'imagecover',
                        carousel: index + 1
                      }
                    })
                  }
                >
                  <img src={`${urlImage}${item.imagePathMobile}`} alt={item.title} title={item.title} width='100%' height='100%'
                    loading='lazy' />
                </a>
              </div>)
            }
          </Carousel>
        }
      </SectionCoverMobile>
    </>
  );
};

const SectionCoverDesktop = styled.div`
  display: block;
  overflow-x: hidden;
  @media (max-width: 767px) {
    display: none;
  }
`;
const SectionCoverMobile = styled.div`
  display: none;
  @media (max-width: 767px) {
      display: block;
      overflow-x: hidden;
  }
`;
export default SectionCover;
